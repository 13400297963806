import { createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardResourcesApi } from '../http';
import { IDashboardResource } from '../models';

const dashboardResourceApi = new DashboardResourcesApi();

export const createDashboardResource = createAsyncThunk(
  'dashboardResource/create',
  async (body: IDashboardResource, { rejectWithValue }) => {
    try {
      return dashboardResourceApi.create(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateDashboardResource = createAsyncThunk(
  'dashboardResource/update',
  async (body: IDashboardResource, { rejectWithValue }) => {
    try {
      return dashboardResourceApi.update(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteDashboardResource = createAsyncThunk(
  'dashboardResource/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return dashboardResourceApi.delete({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findAllDashboardResource = createAsyncThunk(
  'dashboardResource/findAll',
  async (_, { rejectWithValue }) => {
    try {
      return dashboardResourceApi.list();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
