import { createSlice } from '@reduxjs/toolkit';
import { IMainDuties } from '../models';
import { findAllMainDuties } from '../thunks';

interface IInitialState {
  mainDuties: {
    isLoading: boolean;
    result: IMainDuties[] | null;
    error: any;
  };
}

const initialState: IInitialState = {
  mainDuties: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const mainDutiesSlice = createSlice({
  name: 'mainDuties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAllMainDuties.pending, (state) => {
      state.mainDuties = {
        ...state.mainDuties,
        isLoading: true,
      };
    });
    builder.addCase(findAllMainDuties.fulfilled, (state, { payload }) => {
      state.mainDuties = {
        ...state.mainDuties,
        isLoading: false,
        result: payload,
      };
    });
    builder.addCase(findAllMainDuties.rejected, (state, { payload }) => {
      state.mainDuties = {
        ...state.mainDuties,
        isLoading: false,
        error: payload,
      };
    });
  },
});
