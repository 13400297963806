import { createSlice } from '@reduxjs/toolkit';
import { findAllLegalBases } from '../thunks';
import { ILegalBases } from '../model';

interface IInitialState {
  legalBase: {
    isLoading: boolean;
    result: ILegalBases[] | null;
    error: any;
  };
}

const initialState: IInitialState = {
  legalBase: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const legalBaseSlice = createSlice({
  name: 'legalBase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllLegalBases.pending, (state) => {
        state.legalBase = {
          ...state.legalBase,
          isLoading: true,
        };
      })
      .addCase(findAllLegalBases.fulfilled, (state, { payload }) => {
        state.legalBase = {
          ...state.legalBase,
          isLoading: false,
          result: payload,
        };
      })
      .addCase(findAllLegalBases.rejected, (state, { payload }) => {
        state.legalBase = {
          ...state.legalBase,
          isLoading: false,
          error: payload,
        };
      });
  },
});
