import { createSlice } from '@reduxjs/toolkit';
import { findAllAppeals } from '../thunks';
import { IAppeals } from '../models';

interface IAppealSlice {
  appeals: {
    result: IAppeals | null;
    error: any;
    isLoading: boolean;
  };
}

const initialState: IAppealSlice = {
  appeals: {
    result: null,
    error: null,
    isLoading: false,
  },
};

export const appealSlice = createSlice({
  name: 'appealSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllAppeals.pending, (state) => {
        state.appeals = {
          ...state.appeals,
          isLoading: false,
        };
      })
      .addCase(findAllAppeals.fulfilled, (state, { payload }) => {
        state.appeals = {
          ...state.appeals,
          result: payload,
          isLoading: false,
        };
      })
      .addCase(findAllAppeals.rejected, (state, { payload }) => {
        state.appeals = {
          ...state.appeals,
          isLoading: false,
          error: payload,
        };
      });
  },
});
