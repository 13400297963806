import { FC, useEffect } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { Box, Button, Stack } from '@mui/material';
import { TextFieldController } from '../../../components/inputs';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store';
import { createAppeal, findAllAppeals, updateAppeal } from '../../../features/appeals/thunks';
import { useSnackbar } from '../../../common/hooks';
import { useTranslation } from 'react-i18next';

export const AppealsPage: FC = () => {
  const { result } = useAppSelector((state) => state.appealSlice.appeals);
  const { control, handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const handleValid = async (values: any) => {
    const tempData = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, Number(value)]),
    );
    try {
      if (result?.id) {
        await dispatch(updateAppeal({ id: result?.id, ...(tempData as any) }));
      } else {
        await dispatch(createAppeal(tempData as any));
      }
    } catch (error) {
      console.log(error);
    } finally {
      showMessage('Muvaffaqiyatli saqlandi', { variant: 'filled', severity: 'success' });
      dispatch(findAllAppeals());
    }
  };

  useEffect(() => {
    dispatch(findAllAppeals());
  }, [dispatch]);

  useEffect(() => {
    setValue('total_appeals', result?.total_appeals);
    setValue('reviewed_appeals', result?.reviewed_appeals);
    setValue('process_appeals', result?.process_appeals);
  }, [result, setValue]);

  return (
    <CabinetContainer>
      <Box component="form" onSubmit={handleSubmit(handleValid)}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <TextFieldController
            type="number"
            fullWidth
            control={control}
            name="total_appeals"
            label={t('total_appeals')}
          />
          <TextFieldController
            fullWidth
            type="number"
            control={control}
            name="process_appeals"
            label={t('process_appeals')}
          />
          <TextFieldController
            fullWidth
            type="number"
            control={control}
            name="reviewed_appeals"
            label={t('reviewed_appeals')}
          />
        </Stack>
        <Button
          type="submit"
          sx={{ marginLeft: 'auto', display: 'block', marginTop: 2 }}
          variant="contained"
        >
          Saqlash
        </Button>
      </Box>
    </CabinetContainer>
  );
};
