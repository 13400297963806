import { FC, useEffect } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { DataTableCard } from '../../../components/data';
import { useAppDispatch, useAppSelector } from '../../../store';
import { deleteMainDuties, findAllMainDuties } from '../../../features/main-duties/thunks';
import { Button, IconButton } from '@mui/material';
import { IconDeleteCanOutline, IconSquareEditOutline } from '../../../common/icons';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../common/utils';
import { ROUTE_MAIN_DUTIES_CREATE, ROUTE_MAIN_DUTIES_UPDATE } from '../../../common/constants';
import { useTranslation } from 'react-i18next';

export const MainDutiesPage: FC = () => {
  const dispatch = useAppDispatch();
  const { result } = useAppSelector((state) => state.mainDuties.mainDuties);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleEdit = (id: string) => {
    navigate(url(ROUTE_MAIN_DUTIES_UPDATE, { id }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteMainDuties({ id })).then(() => dispatch(findAllMainDuties()));
  };

  const handleRedirect = () => {
    navigate(ROUTE_MAIN_DUTIES_CREATE);
  };

  useEffect(() => {
    dispatch(findAllMainDuties());
  }, [dispatch]);

  return (
    <CabinetContainer>
      <Button onClick={handleRedirect} variant="contained" sx={{ ml: 'auto', mb: 2 }}>
        {t('action.create')}
      </Button>
      <DataTableCard
        columns={[
          { dataKey: 'title', label: 'dashboard.resource.title' },
          { dataKey: 'description', label: 'dashboard.resource.description' },
          {
            dataKey: 'description',
            label: 'action.edit',
            renderItem: ({ id }) => (
              <IconButton onClick={() => handleEdit(id)}>
                <IconSquareEditOutline />{' '}
              </IconButton>
            ),
          },
          {
            dataKey: 'description',
            label: 'action.delete',
            renderItem: ({ id }) => (
              <IconButton onClick={() => handleDelete(id)}>
                <IconDeleteCanOutline />
              </IconButton>
            ),
          },
        ]}
        data={result || []}
      />
    </CabinetContainer>
  );
};
