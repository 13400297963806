import { FC, useEffect } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { DataTableCard } from '../../../components/data';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DASHBOARD_RESOURCES_UPDATE } from '../../../common/constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { findAllDashboardResource } from '../../../features/dashboard-resource/thunks';
import { IconEditRoad } from '../../../common/icons';
import { url } from '../../../common/utils';
import { useTranslation } from 'react-i18next';

export const DashboardResourcesPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { result } = useAppSelector((state) => state.dashboardResource.dashboardResource);

  // const handleNavigate = () => {
  //   navigate(ROUTE_DASHBOARD_RESOURCES_CREATE);
  // };

  const handleEdit = (id: string) => {
    navigate(url(ROUTE_DASHBOARD_RESOURCES_UPDATE, { id }));
  };

  useEffect(() => {
    dispatch(findAllDashboardResource());
  }, [dispatch]);

  return (
    <CabinetContainer>
      {/* <Button onClick={handleNavigate} variant="contained" sx={{ ml: 'auto', mb: 2 }}>
        Yaratish
      </Button> */}
      <DataTableCard
        columns={[
          {
            dataKey: 'type',
            label: t('dashboard.resource.type'),
            renderItem: ({ type }) => (
              <Typography>{t(`dashboard.resource.type.${type}`)}</Typography>
            ),
          },
          { dataKey: 'title', label: t('dashboard.resource.title') },
          { dataKey: 'sub_title', label: t('dashboard.resource.sub_title') },
          { dataKey: 'description', label: t('dashboard.resource.description') },
          {
            dataKey: '',
            label: t('action.edit'),
            renderItem: ({ id }) => (
              <IconButton onClick={() => handleEdit(id)}>
                <IconEditRoad />
              </IconButton>
            ),
          },
        ]}
        showIndex
        data={result || []}
      />
    </CabinetContainer>
  );
};
