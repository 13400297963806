import { useNavigate, useParams } from 'react-router-dom';
import { CabinetContainer } from '../../../components/cabinet-container';
import { LegalBaseCreateForm } from '../../../features/legal-bases/components';
import { useAppDispatch } from '../../../store';
import { useEffect, useState } from 'react';
import {
  createLegalBase,
  findOneLegalBase,
  updateLegalBase,
} from '../../../features/legal-bases/thunks';
import { ROUTE_LEGAL_BASES } from '../../../common/constants';

export const LegalBasesCreatePage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState<any>();

  const handleValid = (values: any) => {
    if (id)
      return dispatch(updateLegalBase({ id, ...values })).then(() => navigate(ROUTE_LEGAL_BASES));
    dispatch(createLegalBase(values)).then(() => navigate(ROUTE_LEGAL_BASES));
  };

  useEffect(() => {
    if (id) {
      dispatch(findOneLegalBase({ id })).then((res) => setDefaultValues(res.payload));
    }
  }, [dispatch, id]);

  return (
    <CabinetContainer>
      <LegalBaseCreateForm handleValid={handleValid} defaultValues={defaultValues} />
    </CabinetContainer>
  );
};
