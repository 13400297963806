export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = ROUTE_HOME;
export const ROUTE_LOGOUT = '/logout';

export const ROUTE_CABINET = '/cabinet';
export const ROUTE_ADMIN = '/admin';

export const ROUTE_AUTH = '/auth/sign-in';
export const ROUTE_AUTH_CURRENT_USER = '/auth/get-current-in';

export const ROUTE_NEWS = '/admin/news';
export const ROUTE_NEWS_CREATE = '/admin/news/create';
export const ROUTE_NEWS_UPDATE = '/admin/news/update/:id';
export const ROUTE_NEWS_VIEW = '/admin/news/view/:id';

export const ROUTE_REGIONS = '/admin/regions';
export const ROUTE_REGIONS_CREATE = '/admin/regions/create';
export const ROUTE_REGIONS_UPDATE = '/admin/regions/update/:id';
export const ROUTE_REGIONS_VIEW = '/admin/regions/view/:id';

export const ROUTE_REGIONAL_ADMINISTRATIONS = '/admin/regional-administrations';
export const ROUTE_REGIONAL_ADMINISTRATIONS_CREATE = '/admin/regional-administrations/create';
export const ROUTE_REGIONAL_ADMINISTRATIONS_UPDATE = '/admin/regional-administrations/update/:id';
export const ROUTE_REGIONAL_ADMINISTRATIONS_VIEW = '/admin/regional-administrations/view/:id';

export const ROUTE_FAQ = '/admin/faq';
export const ROUTE_FAQ_CREATE = '/admin/faq/create';
export const ROUTE_FAQ_UPDATE = '/admin/faq/update/:id';
export const ROUTE_FAQ_VIEW = '/admin/faq/view/:id';

export const ROUTE_USERS = '/admin/users';
export const ROUTE_USERS_CREATE = '/admin/users/create';
export const ROUTE_USERS_UPDATE = '/admin/users/update/:id';
export const ROUTE_USERS_RESET_PASSWORD = '/admin/users/update/:id';

export const ROUTE_OPEN_INFO = '/admin/open-info';
export const ROUTE_OPEN_INFO_CREATE = '/admin/open-info/create';
export const ROUTE_OPEN_INFO_UPDATE = '/admin/open-info/update/:id';
export const ROUTE_OPEN_INFO_VIEW = '/admin/users/open-info/:id';

export const ROUTE_EMAIL = '/admin/email';
export const ROUTE_EMAIL_CREATE = '/admin/email/create';
export const ROUTE_EMAIL_UPDATE = '/admin/email/update/:id';
export const ROUTE_EMAIL_VIEW = '/admin/users/email/:id';

export const ROUTE_MANAGEMENT = '/admin/management';
export const ROUTE_MANAGEMENT_CREATE = '/admin/management/create';
export const ROUTE_MANAGEMENT_UPDATE = '/admin/management/update/:id';
export const ROUTE_MANAGEMENT_VIEW = '/admin/users/management/:id';

export const ROUTE_WANTED_LIST = '/admin/wanted-list';
export const ROUTE_ORGANIZATIONAL_STRUCTURE = '/admin/organizational-structure';

export const ROUTE_APPEALS = '/admin/appeals';
export const ROUTE_DEPARTMENT_ACTIVITY = '/admin/department-activity';
export const ROUTE_DEPARTMENT_ACTIVITY_CREATE = '/admin/department-activity/create';
export const ROUTE_DEPARTMENT_ACTIVITY_UPDATE = '/admin/department-activity/update/:id';

export const ROUTE_DASHBOARD_RESOURCES = '/admin/dashboard/resources';
export const ROUTE_DASHBOARD_RESOURCES_CREATE = '/admin/dashboard/resources/create';
export const ROUTE_DASHBOARD_RESOURCES_UPDATE = '/admin/dashboard/resources/update/:id';

export const ROUTE_CONSTITUTION = '/admin/constitution';
export const ROUTE_CONSTITUTION_CREATE = '/admin/constitution/create';
export const ROUTE_CONSTITUTION_UPDATE = '/admin/constitution/update/:id';

export const ROUTE_MAIN_DUTIES = '/admin/main-duties';
export const ROUTE_MAIN_DUTIES_CREATE = '/admin/main-duties/create';
export const ROUTE_MAIN_DUTIES_UPDATE = '/admin/main-duties/update/:id';

export const ROUTE_LEGAL_BASES = '/admin/legal-bases';
export const ROUTE_LEGAL_BASES_CREATE = '/admin/legal-bases/create';
export const ROUTE_LEGAL_BASES_UPDATE = '/admin/legal-bases/update/:id';
