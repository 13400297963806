import { FC, useEffect, useState } from 'react';
import { MainDutiesCreateForm } from '../../../features/main-duties/components';
import { CabinetContainer } from '../../../components/cabinet-container';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {
  createMainDuties,
  findOneMainDuties,
  updateMainDuties,
} from '../../../features/main-duties/thunks';
import { ROUTE_MAIN_DUTIES } from '../../../common/constants';

export const MainDutiesCreatePage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState<any>();

  const handleValid = (values: any) => {
    if (id)
      return dispatch(updateMainDuties({ id, ...values })).then(() => navigate(ROUTE_MAIN_DUTIES));
    dispatch(createMainDuties(values)).then(() => navigate(ROUTE_MAIN_DUTIES));
  };

  useEffect(() => {
    if (id) {
      dispatch(findOneMainDuties({ id })).then((res) => setDefaultValues(res.payload));
    }
  }, [dispatch, id]);

  return (
    <CabinetContainer>
      <MainDutiesCreateForm handleValid={handleValid} defaultValues={defaultValues} />
    </CabinetContainer>
  );
};
