import { Box, Button, Grid } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { TextFieldController } from '../../../components/inputs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const LegalBaseCreateForm: FC<{
  defaultValues?: any;
  handleValid: (values: any) => void;
}> = ({ handleValid, defaultValues }) => {
  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset, defaultValues]);

  return (
    <Box component="form" onSubmit={handleSubmit(handleValid)}>
      <Grid container spacing={2} alignItems="center" justifyItems="flex-end">
        <Grid item md={5}>
          <TextFieldController
            fullWidth
            control={control}
            name="title"
            label={t('dashboard.resource.title')}
          />
        </Grid>
        <Grid item md={5}>
          <TextFieldController
            fullWidth
            control={control}
            name="redirect_url"
            label={t('constitution.redirect_url')}
          />
        </Grid>
        <Grid item md={2}>
          <Button variant="contained" type="submit">
            {t('action.save')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
