import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_DEPARTMANT_ACTIVITY_CREATE_ONE,
  ENDPOINT_DEPARTMANT_ACTIVITY_DELETE_ONE,
  ENDPOINT_DEPARTMANT_ACTIVITY_FIND_ALL,
  ENDPOINT_DEPARTMANT_ACTIVITY_UPDATE_ONE,
} from '../endpoints';
import { IDepartamentActivity } from '../models';

export class DepartamentAcitivityApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public create(body: IDepartamentActivity) {
    return this.httpClient
      .post(this.url(ENDPOINT_DEPARTMANT_ACTIVITY_CREATE_ONE), body)
      .then((res) => res.data);
  }

  public update(body: IDepartamentActivity) {
    return this.httpClient
      .post(this.url(ENDPOINT_DEPARTMANT_ACTIVITY_UPDATE_ONE), body)
      .then((res) => res.data);
  }

  public delete(body: { id: IDepartamentActivity['id'] }) {
    return this.httpClient
      .post(this.url(ENDPOINT_DEPARTMANT_ACTIVITY_DELETE_ONE), body)
      .then((res) => res.data);
  }

  public list() {
    return this.httpClient
      .post(this.url(ENDPOINT_DEPARTMANT_ACTIVITY_FIND_ALL))
      .then((res) => res.data);
  }
}
