import { RouteObject } from 'react-router-dom';
import {
  ROUTE_ADMIN,
  ROUTE_CONSTITUTION,
  ROUTE_CONSTITUTION_CREATE,
  ROUTE_CONSTITUTION_UPDATE,
  ROUTE_DASHBOARD_RESOURCES,
  ROUTE_DASHBOARD_RESOURCES_CREATE,
  ROUTE_DASHBOARD_RESOURCES_UPDATE,
  ROUTE_DEPARTMENT_ACTIVITY,
  ROUTE_DEPARTMENT_ACTIVITY_CREATE,
  ROUTE_DEPARTMENT_ACTIVITY_UPDATE,
  ROUTE_HOME,
  ROUTE_LEGAL_BASES,
  ROUTE_LEGAL_BASES_CREATE,
  ROUTE_LEGAL_BASES_UPDATE,
  ROUTE_LOGOUT,
  ROUTE_MAIN_DUTIES,
  ROUTE_MAIN_DUTIES_CREATE,
  ROUTE_MAIN_DUTIES_UPDATE,
} from '../common/constants';
import { LayoutBase, LayoutCabinet } from '../layouts';
import { HomePage } from '../pages/home-page';
import { LogoutPage } from '../pages/logout-page';
import { NewsPage } from '../pages/admin/news-page';
import { emailRoutes } from '../features/email/routes';
import { newsRoutes } from '../features/news/routes';
import { regionalAdministrationsRoutes } from '../features/regional-administrations/routes';
import { regionsRoutes } from '../features/regions/routes';
import { faqRoutes } from '../features/faq/routes';
import { userRoutes } from '../features/users/routes';
import { openInfoRoutes } from '../features/open-info/routes';
import { managementRoutes } from '../features/management/routes';
import { wantedListRoutes } from '../features/wanted-list/routes';
import { organizationalRoutes } from '../features/organizational-structure/routes';
import { appealRoutes } from '../features/appeals/routes';
import { DepartmentActivityPage } from '../pages/admin/department-activity';
import { DepartamentActivityCreatePage } from '../pages/admin/department-activity/DepartamentActivityCreatePage';
import {
  DashboardResourcesPage,
  DashboardResourceCreatePage,
} from '../pages/admin/dashboard-resources';
import { ConstitutionCreatePage, ConstitutionPage } from '../pages/admin/constitution-page';
import { MainDutiesCreatePage, MainDutiesPage } from '../pages/admin/main-duties';
import { LegalBasesCreatePage, LegalBasesPage } from '../pages/admin/legal-bases';

export const routes = (pathname: string): RouteObject[] => {
  return [
    {
      path: ROUTE_HOME,
      element: <LayoutBase />,
      caseSensitive: true,
      children: [{ index: true, element: <HomePage />, caseSensitive: true }],
    },
    // cabinet alohida feature qilinganda o'sha feature ichiga olib otish kerak
    {
      path: ROUTE_ADMIN,
      element: <LayoutCabinet />,
      caseSensitive: true,
      children: [
        {
          index: true,
          element: <NewsPage />,
          caseSensitive: true,
        },
        ...wantedListRoutes,
        organizationalRoutes,
        emailRoutes,
        newsRoutes,
        regionalAdministrationsRoutes,
        regionsRoutes,
        managementRoutes,
        faqRoutes,
        userRoutes,
        openInfoRoutes,
        appealRoutes,
        { path: ROUTE_DASHBOARD_RESOURCES, element: <DashboardResourcesPage /> },
        { path: ROUTE_DASHBOARD_RESOURCES_CREATE, element: <DashboardResourceCreatePage /> },
        {
          path: ROUTE_DEPARTMENT_ACTIVITY,
          element: <DepartmentActivityPage />,
        },
        {
          path: ROUTE_DEPARTMENT_ACTIVITY_CREATE,
          element: <DepartamentActivityCreatePage />,
        },
        {
          path: ROUTE_DEPARTMENT_ACTIVITY_UPDATE,
          element: <DepartamentActivityCreatePage />,
        },
        {
          path: ROUTE_DASHBOARD_RESOURCES_UPDATE,
          element: <DashboardResourceCreatePage />,
        },
        {
          path: ROUTE_CONSTITUTION,
          element: <ConstitutionPage />,
        },
        {
          path: ROUTE_CONSTITUTION_CREATE,
          element: <ConstitutionCreatePage />,
        },
        {
          path: ROUTE_CONSTITUTION_UPDATE,
          element: <ConstitutionCreatePage />,
        },
        {
          path: ROUTE_MAIN_DUTIES,
          element: <MainDutiesPage />,
        },
        { path: ROUTE_MAIN_DUTIES_CREATE, element: <MainDutiesCreatePage /> },
        { path: ROUTE_MAIN_DUTIES_UPDATE, element: <MainDutiesCreatePage /> },
        {
          path: ROUTE_LEGAL_BASES,
          element: <LegalBasesPage />,
        },
        { path: ROUTE_LEGAL_BASES_CREATE, element: <LegalBasesCreatePage /> },
        { path: ROUTE_LEGAL_BASES_UPDATE, element: <LegalBasesCreatePage /> },
      ],
    },

    { path: ROUTE_LOGOUT, element: <LogoutPage /> },
  ];
};
