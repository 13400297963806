import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_CONSTITUTION_CREATE_ONE,
  ENDPOINT_CONSTITUTION_DELETE_ONE,
  ENDPOINT_CONSTITUTION_FIND_ALL,
  ENDPOINT_CONSTITUTION_FIND_ONE,
  ENDPOINT_CONSTITUTION_UPDATE_ONE,
} from '../endpoints';
import { IConstitution } from '../models';

export class ConstitutionApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public create(body: IConstitution): Promise<any> {
    return this.httpClient.post(this.url(ENDPOINT_CONSTITUTION_CREATE_ONE), body);
  }

  public deleteOne(body: { id: string }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_CONSTITUTION_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IConstitution): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_CONSTITUTION_UPDATE_ONE), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: string }): Promise<IConstitution> {
    return this.httpClient
      .post(this.url(ENDPOINT_CONSTITUTION_FIND_ONE), body)
      .then((response) => response.data);
  }

  public findAll(): Promise<IConstitution[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_CONSTITUTION_FIND_ALL))
      .then((response) => response.data);
  }
}
