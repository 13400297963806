import { createAsyncThunk } from '@reduxjs/toolkit';
import { MainDutiesApi } from '../http';
import { IMainDuties } from '../models';

const mainDutiesApi = new MainDutiesApi();

export const findAllMainDuties = createAsyncThunk(
  'main-duties/findAll',
  async (_, { rejectWithValue }) => {
    try {
      return await mainDutiesApi.findAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findOneMainDuties = createAsyncThunk(
  'main-duties/find',
  async (body: { id: string }, { rejectWithValue }) => {
    try {
      return await mainDutiesApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createMainDuties = createAsyncThunk(
  'main-duties/create',
  async (body: IMainDuties, { rejectWithValue }) => {
    try {
      return await mainDutiesApi.create(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateMainDuties = createAsyncThunk(
  'main-duties/update',
  async (body: IMainDuties, { rejectWithValue }) => {
    try {
      return await mainDutiesApi.update(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMainDuties = createAsyncThunk(
  'main-duties/delete',
  async (body: { id: string }, { rejectWithValue }) => {
    try {
      return await mainDutiesApi.deleteOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
