import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_MAIN_DUTIES_CREATE_ONE,
  ENDPOINT_MAIN_DUTIES_DELETE_ONE,
  ENDPOINT_MAIN_DUTIES_FIND_ALL,
  ENDPOINT_MAIN_DUTIES_GET_ONE,
  ENDPOINT_MAIN_DUTIES_UPDATE_ONE,
} from '../endpoints';
import { IMainDuties } from '../models';

export class MainDutiesApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public create(body: IMainDuties): Promise<any> {
    return this.httpClient.post(this.url(ENDPOINT_MAIN_DUTIES_CREATE_ONE), body);
  }

  public update(body: IMainDuties): Promise<any> {
    return this.httpClient.post(this.url(ENDPOINT_MAIN_DUTIES_UPDATE_ONE), body);
  }

  public findAll(): Promise<IMainDuties[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_MAIN_DUTIES_FIND_ALL))
      .then((response) => response.data);
  }

  public deleteOne(body: { id: string }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_MAIN_DUTIES_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IMainDuties['id'] }): Promise<IMainDuties> {
    return this.httpClient
      .post(this.url(ENDPOINT_MAIN_DUTIES_GET_ONE), body)
      .then((response) => response.data);
  }
}
