import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppealsApi } from '../http';
import { IAppeals } from '../models';

const appealsApi = new AppealsApi();

export const createAppeal = createAsyncThunk(
  'appeals/create',
  async (body: IAppeals, { rejectWithValue }) => {
    try {
      return await appealsApi.create(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateAppeal = createAsyncThunk(
  'appeals/update',
  async (body: IAppeals, { rejectWithValue }) => {
    try {
      return await appealsApi.update(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findAllAppeals = createAsyncThunk(
  'appeals/findAll',
  async (_, { rejectWithValue }) => {
    try {
      return await appealsApi.findAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
