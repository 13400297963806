import { Box, Button, Grid } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextFieldController } from '../../../components/inputs';
import { useTranslation } from 'react-i18next';

export const MainDutiesCreateForm: FC<{
  defaultValues?: any;
  handleValid: (values: any) => void;
}> = ({ defaultValues, handleValid }) => {
  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset, defaultValues]);

  return (
    <Box component="form" onSubmit={handleSubmit(handleValid)}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item md={5}>
          <TextFieldController
            multiline
            minRows={5}
            fullWidth
            control={control}
            name="title"
            label={t('dashboard.resource.title')}
          />
        </Grid>
        <Grid item md={5}>
          <TextFieldController
            multiline
            minRows={5}
            fullWidth
            control={control}
            name="description"
            label={t('dashboard.resource.description')}
          />
        </Grid>
        <Grid item md={2}>
          <Button variant="contained" type="submit">
            {t('action.save')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
