import { IconExitToApp, IconList, IconSupervisoryAccount, IconSupport } from '../icons';
import { IMenuItem } from '../models';
import {
  ROUTE_APPEALS,
  ROUTE_CONSTITUTION,
  ROUTE_DASHBOARD_RESOURCES,
  ROUTE_DEPARTMENT_ACTIVITY,
  ROUTE_EMAIL,
  ROUTE_FAQ,
  ROUTE_LEGAL_BASES,
  ROUTE_LOGOUT,
  ROUTE_MAIN_DUTIES,
  ROUTE_MANAGEMENT,
  ROUTE_NEWS,
  ROUTE_OPEN_INFO,
  ROUTE_ORGANIZATIONAL_STRUCTURE,
  ROUTE_REGIONAL_ADMINISTRATIONS,
  ROUTE_REGIONS,
  ROUTE_USERS,
  ROUTE_WANTED_LIST,
} from '../constants';

export const SIDEBAR_MENU: IMenuItem[] = [
  {
    path: ROUTE_DASHBOARD_RESOURCES,
    label: 'dashboard.resources',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_NEWS,
    label: 'news',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_EMAIL,
    label: 'email',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_CONSTITUTION,
    label: 'constitution',
    icon: <IconList />,
  },
  {
    path: ROUTE_REGIONAL_ADMINISTRATIONS,
    label: 'regional.administrations',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_LEGAL_BASES,
    label: 'legal_bases',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_MAIN_DUTIES,
    label: 'main_duties',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_REGIONS,
    label: 'regions',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_MANAGEMENT,
    label: 'management',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_FAQ,
    label: 'faq',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_APPEALS,
    label: 'appeals',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_DEPARTMENT_ACTIVITY,
    label: 'department_activity',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_USERS,
    label: 'users',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_ORGANIZATIONAL_STRUCTURE,
    label: 'organizational.structure',
    icon: <IconSupervisoryAccount />,
  },
  {
    path: ROUTE_WANTED_LIST,
    label: 'wanted-list',
    icon: <IconList />,
  },
  {
    path: ROUTE_OPEN_INFO,
    label: 'open.info',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_LOGOUT,
    label: 'logout',
    icon: <IconExitToApp />,
  },
];
