import { FC, useEffect } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { DataTableCard } from '../../../components/data';
import { useAppDispatch, useAppSelector } from '../../../store';
import { Button, IconButton, Typography } from '@mui/material';
import { IconDeleteCanOutline, IconSquareEditOutline } from '../../../common/icons';
import { Link, useNavigate } from 'react-router-dom';
import { url } from '../../../common/utils';
import { ROUTE_LEGAL_BASES_CREATE, ROUTE_LEGAL_BASES_UPDATE } from '../../../common/constants';
import { deleteLegalBase, findAllLegalBases } from '../../../features/legal-bases/thunks';
import { useTranslation } from 'react-i18next';

export const LegalBasesPage: FC = () => {
  const dispatch = useAppDispatch();
  const { result } = useAppSelector((state) => state.legalBase.legalBase);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEdit = (id: string) => {
    navigate(url(ROUTE_LEGAL_BASES_UPDATE, { id }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteLegalBase({ id })).then(() => dispatch(findAllLegalBases()));
  };

  const handleRedirect = () => {
    navigate(ROUTE_LEGAL_BASES_CREATE);
  };

  useEffect(() => {
    dispatch(findAllLegalBases());
  }, [dispatch]);

  return (
    <CabinetContainer>
      <Button onClick={handleRedirect} variant="contained" sx={{ ml: 'auto', mb: 2 }}>
        {t('action.create')}
      </Button>
      <DataTableCard
        columns={[
          { dataKey: 'title', label: 'dashboard.resource.title' },
          {
            dataKey: 'redirect_url',
            label: 'constitution.redirect_url',
            renderItem: ({ redirect_url }) => (
              <Typography component={Link} to={redirect_url} target="_blank">
                {redirect_url}
              </Typography>
            ),
          },
          {
            dataKey: 'description',
            label: 'action.edit',
            renderItem: ({ id }) => (
              <IconButton onClick={() => handleEdit(id)}>
                <IconSquareEditOutline />{' '}
              </IconButton>
            ),
          },
          {
            dataKey: 'description',
            label: 'action.delete',
            renderItem: ({ id }) => (
              <IconButton onClick={() => handleDelete(id)}>
                <IconDeleteCanOutline />
              </IconButton>
            ),
          },
        ]}
        data={result || []}
      />
    </CabinetContainer>
  );
};
