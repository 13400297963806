import { FC, useEffect, useState } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { ConstitutionCreateForm } from '../../../features/constitution/components';
import {
  createConstitution,
  findOneConstitution,
  updateConstitution,
} from '../../../features/constitution/thunks';
import { useAppDispatch } from '../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_CONSTITUTION } from '../../../common/constants';

export const ConstitutionCreatePage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState<any>();

  const handleValid = (values: any) => {
    if (id)
      return dispatch(updateConstitution({ id, ...values })).then(() =>
        navigate(ROUTE_CONSTITUTION),
      );
    dispatch(createConstitution(values)).then(() => navigate(ROUTE_CONSTITUTION));
  };

  useEffect(() => {
    if (id) {
      dispatch(findOneConstitution({ id })).then((res) => setDefaultValues(res.payload));
    }
  }, [id, dispatch]);

  return (
    <CabinetContainer>
      <ConstitutionCreateForm handleValid={handleValid} defaultValues={defaultValues || {}} />
    </CabinetContainer>
  );
};
