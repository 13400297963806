import { Button, Stack, TextareaAutosize } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IDepartamentActivityForm {
  defaultValues?: any;
  handleValid: (values: any) => void;
}
export const DepartamanetActivityForm: FC<IDepartamentActivityForm> = ({
  defaultValues,
  handleValid,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({ defaultValues });

  return (
    <Stack component="form" onSubmit={handleSubmit(handleValid)}>
      <TextareaAutosize
        minRows={8}
        placeholder={t('department_activity')}
        style={{ padding: 12, borderColor: 'snow', outline: 'none', borderRadius: 5 }}
        {...register('description')}
      />
      <Button sx={{ marginLeft: 'auto', mt: 2 }} variant="contained" type="submit" color="primary">
        Saqlash
      </Button>
    </Stack>
  );
};
