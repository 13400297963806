import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { TextFieldController } from '../../../components/inputs';
import { useForm } from 'react-hook-form';
import { IFile, useFiles } from '../../files';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import { IconDeleteCanOutline } from '../../../common/icons';
import { useTranslation } from 'react-i18next';

interface IConstitutionCreateFormProps {
  handleValid: (values: any) => void;
  defaultValues?: any;
}
export const ConstitutionCreateForm: FC<IConstitutionCreateFormProps> = ({
  handleValid,
  defaultValues,
}) => {
  const { control, handleSubmit, setValue, reset } = useForm({ defaultValues });
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  const [files, setFiles] = useState<IFile | null>(defaultValues?.files);
  const { handleInputUpload } = useFiles();

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const response = (await handleInputUpload(e)) as IFile;
    setValue('files', response);
    setFiles(response);
  };

  const handleDeleteImg = () => {
    setFiles(null);
  };

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
      setFiles(defaultValues.files || null);
    }
  }, [defaultValues, reset]);

  return (
    <Grid
      container
      spacing={2}
      component="form"
      onSubmit={handleSubmit(handleValid)}
      justifyContent="flex-end"
    >
      <Grid xs={12} md={4} item>
        <TextFieldController
          control={control}
          fullWidth
          label={t('constitution.description')}
          name="description"
        />
      </Grid>
      <Grid item md={4}>
        <TextFieldController
          control={control}
          name="redirect_url"
          label={t('constitution.redirect_url')}
          fullWidth
        />
      </Grid>
      <Grid item md={4}>
        {files && (
          <img
            height={300}
            src={`${ENDPOINT_BASE_URL}/images/${files?.saved_name}`}
            alt={files?.name}
          />
        )}
        <input
          accept=".jpg,jpeg,image/jpg,image/jpeg,.png,image/png"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
        />
        {files ? (
          <IconButton color="error" onClick={handleDeleteImg}>
            <IconDeleteCanOutline />
          </IconButton>
        ) : (
          <Button onClick={handleUpload} variant="contained">
            {t('constitution.image.upload')}
          </Button>
        )}
      </Grid>
      <Grid item md={12} display="flex">
        <Button sx={{ ml: 'auto' }} type="submit" variant="contained">
          {t('action.save')}
        </Button>
      </Grid>
    </Grid>
  );
};
