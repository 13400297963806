import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILegalBases } from '../model';
import { LegalBasesApi } from '../http';

const legalBaseApi = new LegalBasesApi();

export const createLegalBase = createAsyncThunk(
  'legal-bases/create',
  async (body: ILegalBases, { rejectWithValue }) => {
    try {
      return await legalBaseApi.create(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateLegalBase = createAsyncThunk(
  'legal-bases/update',
  async (body: ILegalBases, { rejectWithValue }) => {
    try {
      return await legalBaseApi.update(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findAllLegalBases = createAsyncThunk(
  'legal-bases/findAll',
  async (_, { rejectWithValue }) => {
    try {
      return await legalBaseApi.findAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteLegalBase = createAsyncThunk(
  'legal-bases/delete',
  async (body: { id: string }, { rejectWithValue }) => {
    try {
      return await legalBaseApi.deleteOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findOneLegalBase = createAsyncThunk(
  'legal-bases/find',
  async (body: { id: ILegalBases['id'] }, { rejectWithValue }) => {
    try {
      return await legalBaseApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
