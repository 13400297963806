import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_LEGAL_BASES_CREATE_ONE,
  ENDPOINT_LEGAL_BASES_DELETE_ONE,
  ENDPOINT_LEGAL_BASES_FIND_ALL,
  ENDPOINT_LEGAL_BASES_GET_ONE,
  ENDPOINT_LEGAL_BASES_UPDATE_ONE,
} from '../endpoints';
import { ILegalBases } from '../model';

export class LegalBasesApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public create(body: ILegalBases): Promise<any> {
    return this.httpClient.post(this.url(ENDPOINT_LEGAL_BASES_CREATE_ONE), body);
  }

  public update(body: ILegalBases): Promise<any> {
    return this.httpClient.post(this.url(ENDPOINT_LEGAL_BASES_UPDATE_ONE), body);
  }

  public findAll(): Promise<ILegalBases[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_LEGAL_BASES_FIND_ALL))
      .then((response) => response.data);
  }

  public deleteOne(body: { id: string }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_LEGAL_BASES_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: ILegalBases['id'] }): Promise<ILegalBases> {
    return this.httpClient
      .post(this.url(ENDPOINT_LEGAL_BASES_GET_ONE), body)
      .then((response) => response.data);
  }
}
