import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_DASHBOARD_RESOURCES_CREATE_ONE,
  ENDPOINT_DASHBOARD_RESOURCES_DELETE_ONE,
  ENDPOINT_DASHBOARD_RESOURCES_FIND_ALL,
  ENDPOINT_DASHBOARD_RESOURCES_UPDATE_ONE,
} from '../endpoints';
import { IDashboardResource } from '../models';

export class DashboardResourcesApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public create(body: IDashboardResource): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_DASHBOARD_RESOURCES_CREATE_ONE), body)
      .then((res) => res.data);
  }

  public update(body: IDashboardResource): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_DASHBOARD_RESOURCES_UPDATE_ONE), body)
      .then((res) => res.data);
  }

  public delete(body: { id: IDashboardResource['id'] }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_DASHBOARD_RESOURCES_DELETE_ONE), body)
      .then((res) => res.data);
  }

  public list(): Promise<IDashboardResource[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_DASHBOARD_RESOURCES_FIND_ALL))
      .then((res) => res.data);
  }
}
