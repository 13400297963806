import { createSlice } from '@reduxjs/toolkit';
import { IDashboardResource } from '../models';
import { findAllDashboardResource } from '../thunks';

interface IInitialState {
  dashboardResource: {
    isLoading: boolean;
    result: IDashboardResource[] | null;
    error: any;
  };
}

const initialState: IInitialState = {
  dashboardResource: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const dashboardResourceSlice = createSlice({
  name: 'dashboardResource',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAllDashboardResource.pending, (state) => {
      state.dashboardResource = {
        ...state.dashboardResource,
        isLoading: true,
      };
    });
    builder.addCase(findAllDashboardResource.fulfilled, (state, { payload }) => {
      state.dashboardResource = {
        ...state.dashboardResource,
        isLoading: false,
        result: payload,
      };
    });
    builder.addCase(findAllDashboardResource.rejected, (state, { payload }) => {
      state.dashboardResource = {
        ...state.dashboardResource,
        isLoading: false,
        error: payload,
      };
    });
  },
});
