import { createSlice } from '@reduxjs/toolkit';
import { IDepartamentActivity } from '../models';
import { findAllDepartamentActivity } from '../thunks';

interface IInitialState {
  departamentActivity: {
    result: IDepartamentActivity[] | null;
    error: any;
    isLoading: boolean;
  };
}

const initialState: IInitialState = {
  departamentActivity: {
    result: null,
    error: null,
    isLoading: false,
  },
};

export const departamentActivitySlice = createSlice({
  name: 'departamentActivity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllDepartamentActivity.pending, (state) => {
        state.departamentActivity = {
          ...state.departamentActivity,
          isLoading: true,
        };
      })
      .addCase(findAllDepartamentActivity.fulfilled, (state, { payload }) => {
        state.departamentActivity = {
          ...state.departamentActivity,
          isLoading: false,
          result: payload,
        };
      })
      .addCase(findAllDepartamentActivity.rejected, (state, { payload }) => {
        state.departamentActivity = {
          ...state.departamentActivity,
          isLoading: false,
          error: payload,
        };
      });
  },
});
