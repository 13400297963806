import { createSlice } from '@reduxjs/toolkit';
import { IConstitution } from '../models';
import { findAllConstitution } from '../thunks';

interface IInitialState {
  constitutions: {
    isLoading: boolean;
    result: IConstitution[] | null;
    error: any;
  };
}

const initialState: IInitialState = {
  constitutions: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const constitutionSlice = createSlice({
  name: 'constitution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllConstitution.pending, (state) => {
        state.constitutions = {
          ...state.constitutions,
          isLoading: true,
        };
      })
      .addCase(findAllConstitution.fulfilled, (state, { payload }) => {
        state.constitutions = {
          ...state.constitutions,
          isLoading: false,
          result: payload,
        };
      })
      .addCase(findAllConstitution.rejected, (state, { payload }) => {
        state.constitutions = {
          ...state.constitutions,
          isLoading: false,
          error: payload,
        };
      });
  },
});
