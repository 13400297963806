import { FC, useEffect, useMemo } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { DepartamanetActivityForm } from '../../../features/departament-activity/components/forms';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  createDepartamentActivity,
  findAllDepartamentActivity,
  updateDepartamentActivity,
} from '../../../features/departament-activity/thunks';
import { ROUTE_DEPARTMENT_ACTIVITY } from '../../../common/constants';

export const DepartamentActivityCreatePage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { result } = useAppSelector((state) => state.departamentActivity.departamentActivity);
  const defaultValue = useMemo(() => result?.find((item) => item.id === id), [result, id]);

  const handleValid = async (values: any) => {
    try {
      if (id) {
        await dispatch(updateDepartamentActivity({ id, ...values }));
      } else {
        await dispatch(createDepartamentActivity(values));
      }
    } catch (error) {
    } finally {
      navigate(ROUTE_DEPARTMENT_ACTIVITY);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(findAllDepartamentActivity());
    }
  }, [id, dispatch]);

  return (
    <CabinetContainer>
      <DepartamanetActivityForm handleValid={handleValid} defaultValues={defaultValue} />
    </CabinetContainer>
  );
};
