import { Box, Button, Grid } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { SelectFieldController, TextFieldController } from '../../../components/inputs';
import { DashboardResourceTypeEnum } from '../enums';
import { IOption } from '../../../common/models';
import { useTranslation } from 'react-i18next';

export const DashboardResourceForm: FC<{ defaultValues?: any; handleValid?: any }> = ({
  defaultValues,
  handleValid,
}) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const { t } = useTranslation();

  const options: IOption[] = Object.values(DashboardResourceTypeEnum).map((item) => ({
    value: item,
    label: t(`dashboard.resource.type.${item}`),
  }));

  return (
    <Box component="form" onSubmit={handleSubmit(handleValid)}>
      <Grid spacing={2} container>
        <Grid item md={4}>
          <SelectFieldController
            label={t('dashboard.resource.type')}
            fullWidth
            control={control}
            options={options}
            name={'type'}
          />
        </Grid>
        <Grid item md={4}>
          <TextFieldController
            label={t('dashboard.resource.title')}
            control={control}
            fullWidth
            name={'title'}
          />
        </Grid>
        <Grid item md={4}>
          <TextFieldController
            label={t('dashboard.resource.sub_title')}
            control={control}
            fullWidth
            name={'sub_title'}
          />
        </Grid>
        <Grid item md={12}>
          <TextFieldController
            multiline
            label={t('dashboard.resource.description')}
            rows={20}
            control={control}
            fullWidth
            name={'description'}
          />
        </Grid>
      </Grid>
      <Button sx={{ display: 'block', ml: 'auto', mt: 2 }} variant="contained" type="submit">
        {t('action.save')}
      </Button>
    </Box>
  );
};
