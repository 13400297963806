import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDepartamentActivity } from '../models';
import { DepartamentAcitivityApi } from '../http';

const departamentActivityApi = new DepartamentAcitivityApi();

export const createDepartamentActivity = createAsyncThunk(
  'departament-activity/create',
  async (body: IDepartamentActivity, { rejectWithValue }) => {
    try {
      return await departamentActivityApi.create(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findAllDepartamentActivity = createAsyncThunk(
  'departament-activity/findAll',
  async (_, { rejectWithValue }) => {
    try {
      return await departamentActivityApi.list();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteDepartamentActivity = createAsyncThunk(
  'departament-activity/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await departamentActivityApi.delete({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateDepartamentActivity = createAsyncThunk(
  'departament-activity/update',
  async (body: IDepartamentActivity, { rejectWithValue }) => {
    try {
      return await departamentActivityApi.update(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
