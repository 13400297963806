import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConstitutionApi } from '../http';
import { IConstitution } from '../models';

const constitutionApi = new ConstitutionApi();

export const createConstitution = createAsyncThunk(
  'constitution/create',
  async (body: IConstitution, { rejectWithValue }) => {
    try {
      return await constitutionApi.create(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteOneConstitution = createAsyncThunk(
  'constitution/delete',
  async (body: { id: string }, { rejectWithValue }) => {
    try {
      return await constitutionApi.deleteOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findAllConstitution = createAsyncThunk(
  'constitution/findAll',
  async (_, { rejectWithValue }) => {
    try {
      return await constitutionApi.findAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateConstitution = createAsyncThunk(
  'constitution/update',
  async (body: IConstitution, { rejectWithValue }) => {
    try {
      return await constitutionApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const findOneConstitution = createAsyncThunk(
  'constitution/findOne',
  async (body: { id: string }, { rejectWithValue }) => {
    try {
      return await constitutionApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
