import { FC, useEffect } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { Button, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  deleteDepartamentActivity,
  findAllDepartamentActivity,
} from '../../../features/departament-activity/thunks';
import { DataTableCard } from '../../../components/data';
import { useNavigate } from 'react-router-dom';
import { IconDelete, IconEditRoad } from '../../../common/icons';
import {
  ROUTE_DEPARTMENT_ACTIVITY_CREATE,
  ROUTE_DEPARTMENT_ACTIVITY_UPDATE,
} from '../../../common/constants';
import { useTranslation } from 'react-i18next';
import { url } from '../../../common/utils';

export const DepartmentActivityPage: FC = () => {
  const { t } = useTranslation();
  const { result } = useAppSelector((state) => state.departamentActivity.departamentActivity);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleDelete = async (id: string) => {
    await dispatch(deleteDepartamentActivity(id));
    dispatch(findAllDepartamentActivity());
  };

  const handleEdit = (id: string) => {
    navigate(url(ROUTE_DEPARTMENT_ACTIVITY_UPDATE, { id }));
  };

  useEffect(() => {
    dispatch(findAllDepartamentActivity());
  }, [dispatch]);

  return (
    <CabinetContainer>
      <Stack>
        <Button
          sx={{ ml: 'auto', mb: 2 }}
          variant="contained"
          onClick={() => navigate(ROUTE_DEPARTMENT_ACTIVITY_CREATE)}
        >
          {t('action.create')}
        </Button>

        <DataTableCard
          columns={[
            { dataKey: 'description', label: t('department_activity') },
            {
              dataKey: '',
              label: t('action.edit'),
              renderItem: ({ id }) => (
                <Button sx={{ margin: 'auto', display: 'block' }} onClick={() => handleEdit(id)}>
                  <IconEditRoad />{' '}
                </Button>
              ),
            },
            {
              dataKey: '',
              label: t('action.delete'),
              renderItem: ({ id }) => (
                <Button
                  sx={{ margin: 'auto', display: 'block' }}
                  color="error"
                  onClick={() => handleDelete(id)}
                >
                  <IconDelete />{' '}
                </Button>
              ),
            },
          ]}
          data={result!}
          showIndex
        />
      </Stack>
    </CabinetContainer>
  );
};
