import { FC, useEffect, useMemo } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { DashboardResourceForm } from '../../../features/dashboard-resource/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  findAllDashboardResource,
  updateDashboardResource,
} from '../../../features/dashboard-resource/thunks';
import { ROUTE_DASHBOARD_RESOURCES } from '../../../common/constants';

export const DashboardResourceCreatePage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { result } = useAppSelector((state) => state.dashboardResource.dashboardResource);

  const handleValid = async (values: any) => {
    try {
      if (id) {
        await dispatch(updateDashboardResource({ id, ...values }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigate(ROUTE_DASHBOARD_RESOURCES);
    }
  };

  const defaultValue = useMemo(() => result?.find((item) => item.id === id), [result, id]);

  useEffect(() => {
    dispatch(findAllDashboardResource());
  }, [dispatch]);

  return (
    <CabinetContainer>
      <DashboardResourceForm handleValid={handleValid} defaultValues={defaultValue} />
    </CabinetContainer>
  );
};
