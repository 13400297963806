import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_APPEALS_CREATE_ONE,
  ENDPOINT_APPEALS_FIND_ALL,
  ENDPOINT_APPEALS_UPDATE_ONE,
} from '../endpoints/appeals.endpoints';
import { IAppeals } from '../models';

export class AppealsApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(): Promise<IAppeals> {
    return this.httpClient
      .post(this.url(ENDPOINT_APPEALS_FIND_ALL))
      .then((response) => response.data);
  }

  public create(body: IAppeals): Promise<IAppeals> {
    return this.httpClient
      .post(this.url(ENDPOINT_APPEALS_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public update(body: IAppeals): Promise<IAppeals> {
    return this.httpClient
      .post(this.url(ENDPOINT_APPEALS_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}
